import type { CssVarsThemeOptions } from '@mui/material';

/**
 * Determines if the input is a pojo.
 *
 * @param value The value to test
 * @returns If the input is a pojo
 */
const isObject = (value: unknown): value is object =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

/**
 * Remaps an object keys and values based on the callback function.
 *
 * @see https://stackoverflow.com/a/14810722
 *
 * @param obj An object to remap
 * @param callbackFn The mapping function
 * @returns The mapped object
 */
function transmuteObject<T extends object>(obj: T, callbackFn: (key: string, value: unknown) => [string, unknown]) {
  const entries = Object.entries(obj);
  const mapEntries: [string, unknown][] = entries.map(([key, value]) =>
    callbackFn(key, isObject(value) ? transmuteObject(value, callbackFn) : value)
  );
  return Object.fromEntries(mapEntries);
}

/**
 * Recursively invokes a callback fn for each nested object reference.
 *
 * @param obj The object to recurse
 * @param callbackFn The function to call back
 */
function recurseObject<T extends object>(obj: T, callbackFn: (nestedObj: object) => void) {
  callbackFn(obj);
  for (const key in obj) {
    const value = obj[key];
    if (isObject(value)) {
      recurseObject(value, callbackFn);
    }
  }
}

/**
 * Processes a theme options object and applies known fixes necessary as part of our Figma export
 * process.
 *
 * Initial context: https://github.com/MontuGroup/montu-web/pull/415/files
 *
 * @param themeObject Incoming brand theme configuration
 * @returns The theme options with manual fix replacements
 */
export const fixThemeOptions = (themeObject: CssVarsThemeOptions): CssVarsThemeOptions => {
  const cssVarPrefix = themeObject.cssVarPrefix;

  const mappedTheme = transmuteObject(themeObject, (key, value) => {
    // Replace "var(--mui-" with "var(--<cssVarPrefix>-"
    if (cssVarPrefix && typeof value === 'string' && value.includes('var(--mui-')) {
      value = value.replace(/var\(--mui-/g, `var(--${cssVarPrefix}-`);
    }

    // https://github.com/mui/mui-design-kits/issues/378
    if (key === 'contrast') {
      key = 'contrastText';
    }
    if (typeof value === 'string' && value.includes('-contrast)')) {
      value = value.replace('-contrast)', '-contrastText)');
    }

    // We had some Figma local variables for typography incorrectly modified
    if (key === 'subtitle 1') {
      key = 'subtitle1';
    }
    if (key === 'subtitle 2') {
      key = 'subtitle2';
    }
    if (key === 'body 1') {
      key = 'body1';
    }
    if (key === 'body 2') {
      key = 'body2';
    }

    return [key, value];
  });

  // Scrub out unexpected hidden elements https://github.com/mui/mui-design-kits/issues/362
  recurseObject(mappedTheme, (nestedObj) => {
    if ('display' in nestedObj && nestedObj['display'] === 'none') {
      delete nestedObj['display'];
      if ('color' in nestedObj && typeof nestedObj['color'] === 'string' && nestedObj['color'].includes('-disabled')) {
        delete nestedObj['color'];
      }
    }
  });

  return mappedTheme;
};

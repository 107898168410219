import type { CssVarsThemeOptions } from '@mui/material/styles';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import cssVarJson from './export.json';

import { fixThemeOptions } from '../../utils/theme';

import SofiaProBold from './fonts/sofia-pro/Sofia Pro Bold Az.otf';
import SofiaProRegular from './fonts/sofia-pro/Sofia Pro Regular Az.otf';

const CSS_VAR_PREFIX = 'montu'; // Needs to be unique to support theme nesting

const baseFontFamily = 'Sofia Pro, Helvetica, Arial, sans-serif';

const customizations: CssVarsThemeOptions = {
  cssVarPrefix: CSS_VAR_PREFIX,
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: baseFontFamily
  },
  components: {
    // MuiAlert overrides to fix the broken logic in extend theme.
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-error-light)`
        },
        standardWarning: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-warning-light)`
        },
        standardInfo: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-info-light)`
        },
        standardSuccess: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-success-light)`
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sofia Pro';
          font-style: normal;
          src: url(${SofiaProRegular}) format('opentype');
          font-weight: 100 500;
        }
        @font-face {
          font-family: 'Sofia Pro';
          font-style: normal;
          src: url(${SofiaProBold}) format('opentype');
          font-weight: 600 900;
        }
      `
    },
    // Temporary fixes to the export. Once the figma is fixed and exported properly, these should be removed
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: '0.875rem',
          fontWeight: 700
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: ({ ownerState }) => ({
          padding: ownerState.variant === 'text' ? '8px 11px' : '8px 22px' // Export bug causing button hover padding change
        }),
        sizeMedium: ({ ownerState }) => ({
          padding: ownerState.variant === 'text' ? '6px 8px' : '6px 16px'
        }),
        sizeSmall: ({ ownerState }) => ({
          padding: ownerState.variant === 'text' ? '4px 5px' : '4px 10px'
        })
      }
    }
  }
};

export const montuJSON = fixThemeOptions(deepmerge(cssVarJson, customizations) as CssVarsThemeOptions);

export const montu = extendTheme(montuJSON);

export default montu;

import styled from '@emotion/styled';
import { Box } from '@mui/material';

// Base styles shared by both Viewer and BoxViewer
const baseViewerStyles = {
  width: '100%',
  height: '60vh',
  marginBottom: '1rem',
  border: 'none'
};

// Viewer component (iframe)
export const IframeViewer = styled('iframe')(baseViewerStyles);

// BoxViewer component (div)
export const BoxViewer = styled('div')(baseViewerStyles, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const ErrorViewer = styled('div')(baseViewerStyles, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start'
});

export const StyledBox = styled(Box)({
  whiteSpace: 'pre-wrap'
});

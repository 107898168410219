import type { CssVarsTheme } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import type { ReactNode } from 'react';
import ColorSchemeToggle from './ColorSchemeToggle';

type BrandThemeProviderProps = {
  theme?: CssVarsTheme;
  children: ReactNode;
};

export const BrandThemeProvider = ({ children, theme }: BrandThemeProviderProps) => {
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <ColorSchemeToggle />
      {children}
    </CssVarsProvider>
  );
};

export default BrandThemeProvider;

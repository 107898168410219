import type { CssVarsThemeOptions } from '@mui/material/styles';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import cssVarJson from './export.json';

import { fixThemeOptions } from '../../utils/theme';

import ProximaNovaBlack from './fonts/proxima-nova/proxima_nova_black.woff';
import ProximaNovaBlack2 from './fonts/proxima-nova/proxima_nova_black.woff2';
import ProximaNovaBold from './fonts/proxima-nova/proxima_nova_bold.woff';
import ProximaNovaBold2 from './fonts/proxima-nova/proxima_nova_bold.woff2';
import ProximaNovaExtrabold from './fonts/proxima-nova/proxima_nova_extrabold.woff';
import ProximaNovaExtrabold2 from './fonts/proxima-nova/proxima_nova_extrabold.woff2';
import ProximaNovaLight from './fonts/proxima-nova/proxima_nova_light.woff';
import ProximaNovaLight2 from './fonts/proxima-nova/proxima_nova_light.woff2';
import ProximaNovaRegular from './fonts/proxima-nova/proxima_nova_regular.woff';
import ProximaNovaRegular2 from './fonts/proxima-nova/proxima_nova_regular.woff2';
import ProximaNovaSemibold from './fonts/proxima-nova/proxima_nova_semibold.woff';
import ProximaNovaSemibold2 from './fonts/proxima-nova/proxima_nova_semibold.woff2';
import ProximaNovaThin from './fonts/proxima-nova/proxima_nova_thin.woff';
import ProximaNovaThin2 from './fonts/proxima-nova/proxima_nova_thin.woff2';
import ProximaNovaThinItalic from './fonts/proxima-nova/proxima_nova_thin_italic.woff';
import ProximaNovaThinItalic2 from './fonts/proxima-nova/proxima_nova_thin_italic.woff2';

const CSS_VAR_PREFIX = 'leafio'; // Needs to be unique to support theme nesting

const baseFontFamily = 'Proxima Nova, Helvetica, Arial, sans-serif';

const customizations: CssVarsThemeOptions = {
  cssVarPrefix: CSS_VAR_PREFIX,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 998,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontFamily: 'Proxima Nova'
  },
  components: {
    // MuiAlert overrides to fix the broken logic in extend theme.
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-error-light)`
        },
        standardWarning: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-warning-light)`
        },
        standardInfo: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-info-light)`
        },
        standardSuccess: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-success-light)`
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Proxima Nova';
          font-weight: 900;
          src: url(${ProximaNovaBlack2}) format('woff2'), url(${ProximaNovaBlack}) format('woff');
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          font-weight: 800;
          src: url(${ProximaNovaExtrabold2}) format('woff2'), url(${ProximaNovaExtrabold}) format('woff');
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          font-weight: 700;
          src: url(${ProximaNovaBold2}) format('woff2'), url(${ProximaNovaBold}) format('woff');
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          font-weight: 600;
          src: url(${ProximaNovaSemibold2}) format('woff2'), url(${ProximaNovaSemibold}) format('woff');
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          font-weight: 400;
          src: url(${ProximaNovaRegular2}) format('woff2'), url(${ProximaNovaRegular}) format('woff');
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          font-weight: 300;
          src: url(${ProximaNovaLight2}) format('woff2'), url(${ProximaNovaLight}) format('woff');
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          font-weight: 100;
          src: url(${ProximaNovaThin2}) format('woff2'), url(${ProximaNovaThin}) format('woff');
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          font-weight: 100;
          src: url(${ProximaNovaThinItalic2}) format('woff2'), url(${ProximaNovaThinItalic}) format('woff');
          font-display: auto;
          font-style: italic;
          font-stretch: normal;
        }
      `
    },
    // Temporary fixes to the export. Once the figma is fixed and exported properly, these should be removed
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 700,
          fontFamily: baseFontFamily
        },
        icon: ({ theme: t, ownerState }) => ({
          // The default color is inherited by the Chip CSS color, we want instead to be inherited by the chip color prop
          color:
            ownerState.color && ownerState.color !== 'default'
              ? t.palette[ownerState.color].dark
              : t.palette.Avatar.defaultBg
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: ({ ownerState }) => ({
          ':hover': {
            padding: ownerState.variant === 'outlined' ? '7px 21px !important' : '8px 22px' // Export bug causing button hover padding change
          }
        }),
        sizeMedium: ({ ownerState }) => ({
          ':hover': {
            padding: ownerState.variant === 'outlined' ? '5px 15px !important' : '6px 16px'
          }
        }),
        sizeSmall: ({ ownerState }) => ({
          ':hover': {
            padding: ownerState.variant === 'outlined' ? '3px 9px !important' : '4px 10px'
          }
        })
      }
    }
  }
};

export const leafioJSON = fixThemeOptions(deepmerge(cssVarJson, customizations) as CssVarsThemeOptions);

export const leafio = extendTheme(leafioJSON);

export default leafio;

import { Flag } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

interface PatientBasicInfoProps {
  isLoading: boolean;
  data: {
    FirstName: string;
    LastName: string;
    redFlag: boolean;
    redFlagComment?: string;
    patient_code: string;
    DoB: string;
    calculatedAge: number;
  };
}

const PatientBasicInfo: React.FC<PatientBasicInfoProps> = ({ isLoading, data }) => {
  return (
    <Box pl={0.5} my={3}>
      <Box>
        {isLoading && (
          <Box justifyContent="center" alignItems="center" gap="1" display="flex">
            <CircularProgress size={25} />
            <Typography variant="h4">Loading</Typography>
          </Box>
        )}
        <Typography fontWeight={600}>
          {data.FirstName} {data.LastName}
          {data.redFlag && (
            <Tooltip title={data.redFlagComment || ''}>
              <Flag fontSize="small" color="error" />
            </Tooltip>
          )}{' '}
        </Typography>
      </Box>
      <Typography data-dd-privacy="allow" variant="body2">
        {data.patient_code}
      </Typography>
      <Typography variant="body2">DoB: {DateTime.fromISO(data.DoB).toFormat('dd-MMM-yyyy')}</Typography>
      <Typography variant="body2">Age: {data.calculatedAge}</Typography>
    </Box>
  );
};

export default PatientBasicInfo;

import { Button } from '@montugroup/design-system';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Card, CardContent, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { EditModeContext } from './EditModeContextProvider';

interface CommonProps {
  title: string;
  editable?: boolean;
  children: React.ReactNode;
}

interface EditableSectionsPropsWithForm extends CommonProps {
  formContext: UseFormReturn<FieldValues>;
  actions: {
    onCancel?: () => void;
    onSubmit: (data: FieldValues) => void;
  };
}

interface EditableSectionsPropsWithoutForm extends CommonProps {
  formContext?: undefined;
  actions: {
    onCancel?: () => void;
    onSubmit: () => void;
  };
}

type EditableSectionsProps = EditableSectionsPropsWithForm | EditableSectionsPropsWithoutForm;

function EditableSections({
  title,
  editable = true,
  formContext,
  actions: { onCancel, onSubmit },
  children
}: EditableSectionsProps) {
  const { isEditMode, setEditMode } = useContext(EditModeContext);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    if (formContext) {
      formContext.reset();
    }
    setEditMode(false);

    if (onCancel) {
      onCancel();
    }
  };
  const handleDone = () => {
    if (formContext) {
      formContext.handleSubmit((data) => {
        onSubmit(data);
        setEditMode(false);
      })();
    } else {
      if (onSubmit) {
        onSubmit({});
      }
      setEditMode(false);
    }
  };

  return (
    <Card
      sx={{
        '&:hover': {
          ...(!isEditMode && { bgcolor: 'action.hover' }),
          '& .MuiButtonBase-root': {
            visibility: 'visible'
          }
        }
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            borderBottom: '1px solid',
            borderColor: (theme) => theme.palette.action.disabled,
            alignItems: 'center',
            pb: 2
          }}
        >
          <Typography component="h2" variant="h6" color="primary">
            {title}
          </Typography>
          {editable && (
            <Stack direction="row" spacing={2}>
              {!isEditMode && (
                <Tooltip title="edit" placement="top" sx={{ visibility: 'hidden' }}>
                  <IconButton aria-label="edit" onClick={handleEdit}>
                    <ModeEditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isEditMode && (
                <>
                  <Button color="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button onClick={handleDone}>Done</Button>
                </>
              )}
            </Stack>
          )}
        </Stack>
        {children}
      </CardContent>
    </Card>
  );
}

export default EditableSections;

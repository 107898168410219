import settings from '@/data/constants';
import { getAuthData } from '@/data/service/authService';

import { Table } from '@montugroup/design-system';

import { formatTableColumns } from './NotificationOfTreatmentTable.config';
import type { NotificationOfTreatmentTableProps } from './NotificationOfTreatmentTable.types';

export const NotificationOfTreatmentTable = ({ data }: NotificationOfTreatmentTableProps) => {
  const { token } = getAuthData();
  const downloadFile = async (documentPath: string) => {
    fetch(`${settings.url}/patientNote/download?key=${documentPath}`, {
      method: 'GET',
      headers: new Headers({
        authorization: `Bearer ${token}`
      })
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = documentPath;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return <Table data={data} columns={formatTableColumns(downloadFile)} noDataFoundMessage="No documents found" />;
};

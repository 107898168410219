import type { GetDocumentResponse } from '@montugroup/pms-api-contracts/build/document';
import { NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID } from './DocumentsTab.config';

/**
 * Filters an array of documents based on the document_type_id.
 * If documentTypeId is not provided, returns everything except items with document_type_id === NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID.
 *
 * @param data - The array of documents to filter.
 * @param [documentTypeId] - The document_type_id to filter by.
 * @returns - The filtered array of documents.
 */
export const filterByDocumentTypeId = (
  data: Partial<GetDocumentResponse>[],
  documentTypeId?: number
): Partial<GetDocumentResponse>[] => {
  if (!documentTypeId) {
    return data.filter((item) => item.document_type_id !== NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID);
  }
  return data.filter((item) => item.document_type_id === documentTypeId);
};

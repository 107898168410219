import type { SxProps, Theme } from '@mui/material';

export enum AlertSeverity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export type AlertModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cancelText: string;
  confirmText: string;
  severity?: AlertSeverity;
  showIcon?: boolean;
  title?: string;
  body?: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>; // Add this line
};

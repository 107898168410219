import { RankTier } from '@montugroup/pms-api-contracts';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Alert, Card, IconButton, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type {
  Attribute,
  FilteredProduct,
  FilteredProductByFormulation,
  ProductFilter as ProductFilterType
} from '@/api';
import { isRankDecoration, isStockDecoration } from '@/api';
import { ProductFilter } from '@/shared-ui';
import type { RadioOption } from '@/shared-ui/FeedbackRadioGroup/FeedbackRadioGroup';
import type { Filters } from '@/shared-ui/ProductFilter/ProductFilter';
import ProductSelection from '@/shared-ui/ProductSelection/ProductSelection';
import { useAppStore } from '@/state-management';
import { Logger } from '@/utils/logger';

import type { MedicationPadItemCancelData, MedicationPadItemType } from '../PrescriptionPad';
import { getAnswerDisplayPositions, getProductDisplayName } from '../PrescriptionPad';
import {
  PRODUCT_INTERVAL_DEFAULT,
  PRODUCT_QUANTITY_DEFAULT,
  PRODUCT_REPEATS_DEFAULT
} from '../ProductDetailsCard/ProductDetailsCard.constants';
import type { ProductSelectionFeedbackProps } from '../ProductSelectionFeedback/ProductSelectionFeedback';
import ProductSelectionFeedback from '../ProductSelectionFeedback/ProductSelectionFeedback';

import {
  FF_PAT_PRESCRIPTION_DOSAGE_MIN_LENGTH_VALIDATION,
  FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION
} from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { MedicationPrescriptionStatus } from '@montugroup/prescription-contracts';
import type { PrescriptionPadItemDetailType } from './PrescriptionItemEditor.types';
import { PrescriptionPadItemDetail } from './PrescriptionItemEditor.types';
import { PrescriptionItemAlert } from './components/PrescriptionItemAlert';
import { PrescriptionItemAttributesEditor } from './components/PrescriptionItemAttributesEditor';
import { RecommendationInfoAlert } from './components/RecommendationInfoAlert';
import { useFilteredMedicationOptions, usePrescriptionItemFeatureFlags } from './hooks';

const logger = new Logger('PrescriptionItemEditor');

export type MedicationPadItemEditorProps = {
  /**
   * Unique identifier to find the prescription item that matches the ones in Prescription Provider
   */
  medicationItemId: string;
  /**
   * All the available filters
   * TODO: review whether we get this directly from react-query, including the feedback props
   */
  filterDataSource: ProductFilterType;
  medicationDataSource: FilteredProductByFormulation;
  productSelectionFeedbackProps: ProductSelectionFeedbackProps;
  /**
   * Invoked whenever the medication, dosage, interval and repeats etc. is changed.
   */
  onMedicationPadItemChange?: (item: MedicationPadItemType) => void;
  onRemoveIconClick?: (id: string) => void;
  onPreviewIconClick?: (item: FilteredProduct) => void;
};

/**
 * Used to edit a prescription item, including medication selection, dosage and interval.
 * This component relies on the `Prescription` provider and will not work without it.
 */
export const MedicationPadItemEditor = ({
  medicationItemId,
  filterDataSource,
  medicationDataSource,
  onRemoveIconClick,
  onPreviewIconClick,
  productSelectionFeedbackProps
}: MedicationPadItemEditorProps) => {
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];
  const ffPatPrescriptionDosageMinLengthValidation = flags[FF_PAT_PRESCRIPTION_DOSAGE_MIN_LENGTH_VALIDATION];

  const { recommendationInfo, recommendedMedicationsCount } = usePrescriptionItemFeatureFlags();
  const {
    prescriptionPad: { medicationPadItems },
    prescriptionHistory: { itemsToReissue }
  } = useAppStore.use.prescriptionAssistant();
  const updateMedicationPadItemById = useAppStore.use.updateMedicationPadItemById();
  const moveItemToCancelFromReissue = useAppStore.use.moveItemToCancelFromReissue();
  const updatePrescriptionHistoryItemsToReissue = useAppStore.use.updatePrescriptionHistoryItemsToReissue();

  const medicationPadItem = useMemo(() => {
    return (
      medicationPadItems.find((existingItem) => existingItem.medicationPadItemId === medicationItemId) || {
        medicationPadItemId: medicationItemId
      }
    );
  }, [medicationPadItems, medicationItemId]);

  const {
    /**
     * ID that identifies this current medication pad item
     */
    medicationPadItemId: prescriptionItemId,
    /**
     * Item's detail, including medication (product), dosage, interval etc.
     */
    medicationPadItem: medicationPadItemDetail,
    reissueStatus,
    filters: prescriptionItemFilters,
    moreProductsLoaded,
    errorMessage
  } = medicationPadItem;

  const [isMedicationSelectionOpen, setIsMedicationSelectionOpen] = useState(false);
  const [showViewMoreProducts, setShowViewMoreProducts] = useState(true);
  const [isReissued, setIsReissued] = useState(reissueStatus?.isReissued || false);
  const [cancelAlertData, setCancelAlertData] = useState<MedicationPadItemCancelData | null>(null);

  const {
    selectedFilters,
    medicationOptions,
    filteredMedicationOptions,
    calculateMedicationOptionsFromFilters,
    isReady
  } = useFilteredMedicationOptions({
    initialFilters: prescriptionItemFilters,
    medicationDataSource,
    displayAllOptions: !showViewMoreProducts || moreProductsLoaded,
    prescribedMedicationId: medicationPadItemDetail?.productId
  });

  const validateAndUpdateMedicationPadItemDetail = useCallback(
    (partialDetail: Partial<PrescriptionPadItemDetailType>) => {
      if (!medicationPadItemDetail && !partialDetail?.productId && !partialDetail?.productName) {
        return;
      }
      const updatedPrescriptionItemDetail: PrescriptionPadItemDetailType = {
        ...medicationPadItemDetail,
        ...partialDetail
      } as PrescriptionPadItemDetailType; // assertion is safe, as the conditional above will early return if required props don't exit.

      const validation = PrescriptionPadItemDetail.safeParse(updatedPrescriptionItemDetail);
      if (!validation.success) {
        logger.error('validateAndUpdatePrescriptionItemDetail: invalid prescription details used', {
          updatedPrescriptionItemDetail
        });
        return;
      }
      return updatedPrescriptionItemDetail;
    },
    [medicationPadItemDetail]
  );

  const getPrescriptionItemDetailFromMedication = (
    medication: FilteredProduct
  ): PrescriptionPadItemDetailType | undefined => {
    const updatedPrescriptionItemDetail = validateAndUpdateMedicationPadItemDetail({
      productId: medication.id,
      productName: medication.productName,
      supplierName: medication.supplierName
    });

    return updatedPrescriptionItemDetail;
  };

  /**
   * Currently selected medication derived from the context
   */
  const selectedMedication = useMemo(() => {
    if (filteredMedicationOptions.length === 0) {
      return undefined;
    }
    // if nothing is prescribed yet, but there are options available, set the first one
    if (!medicationPadItemDetail?.productId) {
      return filteredMedicationOptions[0];
    }

    const matchedMedicationInOptions = filteredMedicationOptions.find(
      (medication) => medication.id === medicationPadItemDetail?.productId
    );
    return matchedMedicationInOptions || filteredMedicationOptions[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMedicationOptions, medicationPadItemDetail?.productId]);

  /**
   * this effect is specifically for handling out of stock medication
   * and whether to dispatch the appropriate first option to the state management or not
   */
  useEffect(() => {
    if (!selectedMedication) {
      return;
    }

    if (filteredMedicationOptions.length === 0) {
      return;
    }

    if (isStockDecoration(selectedMedication.decoration) && selectedMedication.decoration.isOutOfStock) {
      // when it's out of stock, go for the first option in the list. but if even that option is out of stock, the exit and don't dispatch
      if (
        isStockDecoration(filteredMedicationOptions[0].decoration) &&
        filteredMedicationOptions[0].decoration.isOutOfStock
      ) {
        return;
      }

      // if it's out of stock, don't clear the reissue status if it exists
      const updatedPrescriptionItemDetail = getPrescriptionItemDetailFromMedication(filteredMedicationOptions[0]);
      if (!updatedPrescriptionItemDetail) {
        return;
      }
      const updatedPrescriptionItem: MedicationPadItemType = {
        ...medicationPadItem,
        medicationPadItem: updatedPrescriptionItemDetail,
        filters: selectedFilters,
        reissueStatus: isReissued ? reissueStatus : undefined
      };

      updateMedicationPadItemById(updatedPrescriptionItem, ffPatPrescriptionDosageMinLengthValidation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedication, filteredMedicationOptions.length]);

  /**
   * this effect is specifically used to clear the medication selection from state management,
   * when no available medication options are there to be selected
   */
  useEffect(() => {
    if (!isReady) {
      return;
    }

    if (filteredMedicationOptions.length > 0) {
      return;
    }

    const updatedPrescriptionItem: MedicationPadItemType = {
      ...medicationPadItem,
      medicationPadItem: undefined,
      filters: selectedFilters,
      reissueStatus: undefined
    };
    updateMedicationPadItemById(updatedPrescriptionItem, ffPatPrescriptionDosageMinLengthValidation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMedicationOptions.length, isReady]);

  const handleMoveItemToCancelFromReissue = () => {
    if (!medicationPadItemDetail || !itemsToReissue?.length) {
      setCancelAlertData(null);
      return;
    }
    // Check if the user is changing a reissued medication
    const medicationToCancel = itemsToReissue.find(
      (item) =>
        item.medicationPadItem?.productId === medicationPadItemDetail?.productId && !item.reissueStatus?.isOutOfStock
    );

    if (!medicationToCancel) {
      setCancelAlertData(null);
      return;
    }

    const isInactivePrescription =
      medicationToCancel?.prescriptionStatus === MedicationPrescriptionStatus.Cancelled ||
      medicationToCancel?.prescriptionStatus === MedicationPrescriptionStatus.Expired;

    if (ffV4PrescriptionAssistantCancelPrescribedMedication && isInactivePrescription) {
      const updatedItemsToReissue = itemsToReissue.filter(
        (item) => item.medicationPadItem?.productId !== medicationPadItemDetail?.productId
      );

      updatePrescriptionHistoryItemsToReissue(updatedItemsToReissue);
      return;
    }

    // Move the medication from reissue to cancel in the state
    moveItemToCancelFromReissue({
      productId: Number(medicationPadItemDetail?.productId),
      displayName: getProductDisplayName(
        medicationPadItemDetail?.productName,
        medicationPadItemDetail?.description,
        medicationPadItemDetail.supplierName
      )
    });
    // Alert the user that the medication will be cancelled
    if (medicationToCancel.medicationPadItem?.productName) {
      setCancelAlertData({
        supplierName: medicationToCancel.medicationPadItem?.supplierName,
        medicationName: medicationToCancel.medicationPadItem?.productName
      });
    }
  };

  const handleFilterChanged = (changedFilters: Filters) => {
    handleMoveItemToCancelFromReissue();

    calculateMedicationOptionsFromFilters(changedFilters);
    setIsReissued(false);
  };

  const handleMedicationSelect = () => {
    setIsReissued(false);
  };

  const handleMedicationChange = useCallback(
    (changedSelection: FilteredProduct) => {
      // validate - move this part to shared util function
      if (changedSelection.id === medicationPadItemDetail?.productId) {
        // same thing, don't dispatch anything
        return;
      }
      // If the user is changing a reissued medication, move it to cancel
      handleMoveItemToCancelFromReissue();

      // validate for OOS - clear out previous selection if OOS selected.
      if (isStockDecoration(changedSelection.decoration) && changedSelection.decoration.isOutOfStock) {
        const updatedPrescriptionItem: MedicationPadItemType = {
          ...medicationPadItem,
          medicationPadItem: undefined
        };
        updateMedicationPadItemById(updatedPrescriptionItem, ffPatPrescriptionDosageMinLengthValidation);
        return;
      }

      const { id, productName, supplierName, attributes } = changedSelection;
      const updatedPrescriptionItemDetail = validateAndUpdateMedicationPadItemDetail({
        ...medicationPadItemDetail,
        productId: id,
        productName: productName,
        supplierName: supplierName,
        repeats: attributes?.repeats?.default ?? PRODUCT_REPEATS_DEFAULT,
        interval: attributes?.interval?.default ?? PRODUCT_INTERVAL_DEFAULT,
        quantity: attributes?.quantity?.default ?? PRODUCT_QUANTITY_DEFAULT,
        dosage: attributes?.dosage ?? ''
      });

      if (!updatedPrescriptionItemDetail) {
        logger.error('handleMedicationChange: validation failed', {
          updatedPrescriptionItemDetail
        });
        return;
      }

      // check for ranking tiers and feedback
      const isTopRecommended =
        isRankDecoration(changedSelection.decoration) && changedSelection.decoration.tier === RankTier.First;

      let feedback = medicationPadItem.feedback ? { ...medicationPadItem.feedback } : undefined;

      if (isTopRecommended && !isReissued) {
        feedback = undefined;
        setShowViewMoreProducts(true);
      }

      const updatedPrescriptionItem: MedicationPadItemType = {
        ...medicationPadItem,
        medicationPadItem: updatedPrescriptionItemDetail,
        filters: selectedFilters,
        feedback,
        reissueStatus: isReissued ? reissueStatus : undefined
      };
      updateMedicationPadItemById(updatedPrescriptionItem, ffPatPrescriptionDosageMinLengthValidation);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReissued, medicationPadItem, selectedFilters]
  );

  const handleAttributesChange = (attribute: Attribute, value: string | number) => {
    if (!medicationPadItemDetail) {
      return;
    }
    const updatedPrescriptionItemDetail = validateAndUpdateMedicationPadItemDetail({
      ...medicationPadItemDetail,
      [attribute]: value
    });

    if (!updatedPrescriptionItemDetail) {
      logger.error('handleAttributeChange: validation failed', {
        updatedPrescriptionItemDetail
      });
      return;
    }

    const updatedPrescriptionItem: MedicationPadItemType = {
      ...medicationPadItem,
      medicationPadItem: updatedPrescriptionItemDetail
    };
    updateMedicationPadItemById(updatedPrescriptionItem, ffPatPrescriptionDosageMinLengthValidation);
  };

  const handleSurveyFeedbackChange = (answerId: number, randomAnswers: RadioOption[]) => {
    setIsMedicationSelectionOpen(true);
    setShowViewMoreProducts(false);
    if (answerId < 1) {
      logger.error('handleFeedbackChange:invalid answer id', {
        answerId
      });
      return;
    }
    const updatedPrescriptionItem: MedicationPadItemType = {
      ...medicationPadItem,
      moreProductsLoaded: true,
      feedback: {
        questionAsked: productSelectionFeedbackProps.questionId,
        answerSelected: answerId,
        answerDisplayPositions: getAnswerDisplayPositions(productSelectionFeedbackProps.answers, randomAnswers)
      }
    };

    updateMedicationPadItemById(updatedPrescriptionItem, ffPatPrescriptionDosageMinLengthValidation);
  };

  const handlePreviewIconClick = (medication: FilteredProduct) => {
    setIsMedicationSelectionOpen(false);
    onPreviewIconClick?.(medication);
  };

  const canRemoveMedication = () => {
    if (ffV4PrescriptionAssistantCancelPrescribedMedication) {
      return true;
    }

    return Boolean(selectedMedication?.productName) || Boolean(medicationPadItems.length);
  };

  // keep the menu list open when feedback has been selected. This ensures that `ProductSelection` is only "controlled" when `isMedicationSelectionOpen` is true
  const keepProductSelectionOpen = isMedicationSelectionOpen ? { open: true } : null;
  const surveyFeedbackElement = [
    showViewMoreProducts && !moreProductsLoaded && medicationOptions.length > recommendedMedicationsCount && (
      <ProductSelectionFeedback
        key={`productSelectFeedback-${prescriptionItemId}`}
        {...productSelectionFeedbackProps}
        onConfirm={handleSurveyFeedbackChange}
      />
    ),
    medicationOptions.length < recommendedMedicationsCount && (
      <Alert key={`all-product-shown-${prescriptionItemId}`} severity="info">
        All products are showing
      </Alert>
    )
  ];

  return (
    <Card aria-label="Medication selector" raised>
      <Stack direction="row" padding={7}>
        <Stack minWidth="90%" spacing={6}>
          <PrescriptionItemAlert
            cancelAlertData={cancelAlertData}
            errorMessage={errorMessage}
            reissueStatus={reissueStatus}
          />

          <ProductFilter
            selectedFilters={selectedFilters}
            productFilter={filterDataSource}
            onChange={handleFilterChanged}
          />
          {/* Recommendation Info */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <RecommendationInfoAlert info={recommendationInfo} />
          </Stack>
          {/* Medication Selection */}
          {!!medicationOptions?.length && (
            <>
              <ProductSelection
                {...keepProductSelectionOpen}
                value={selectedMedication}
                options={filteredMedicationOptions}
                onChange={handleMedicationChange}
                onSelect={handleMedicationSelect}
                onClose={() => setIsMedicationSelectionOpen(false)}
                onPreviewIconClick={handlePreviewIconClick}
                MenuProps={{
                  sx: {
                    ['& .MuiSvgIcon-root']: {
                      color: 'orange.main'
                    },
                    overflowY: 'scroll'
                  }
                }}
                bottomElement={surveyFeedbackElement}
              />
              <PrescriptionItemAttributesEditor
                quantity={medicationPadItemDetail?.quantity ?? PRODUCT_QUANTITY_DEFAULT}
                interval={medicationPadItemDetail?.interval ?? PRODUCT_INTERVAL_DEFAULT}
                repeats={medicationPadItemDetail?.repeats ?? PRODUCT_REPEATS_DEFAULT}
                dosage={medicationPadItemDetail?.dosage ?? ''}
                selectedMedication={selectedMedication}
                onChange={handleAttributesChange}
              />
            </>
          )}
        </Stack>

        {/* Delete Button */}
        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={2} width="100%">
          <IconButton
            aria-label="remove"
            onClick={() => onRemoveIconClick?.(prescriptionItemId)}
            sx={{ padding: 0 }}
            disabled={!canRemoveMedication()}
          >
            <DeleteOutlineOutlinedIcon fontSize="large" />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};

import StatusChip from '@/shared-ui/StatusChip/StatusChip';
import type { GetDocumentResponse } from '@montugroup/pms-api-contracts/build/document';
import { Box, Link } from '@mui/material';
import type { CellContext } from '@tanstack/react-table';
import { DateTime } from 'luxon';

/**
 * Get color by document status
 * @param status - document state
 * @returns - document status code
 */

export const getStatusColor = (status?: string) => {
  switch (status) {
    case 'Latest':
      return 'success';

    case 'Pending':
      return 'warning';

    case 'Inactive':
    default:
      return 'default';
  }
};

/**
 * Render the status as a coloured chip
 * @param params.value - this should be the `status` text as this is what this cell is used for
 * @returns the rendered status chip
 */
export const renderStatusChipCell = (statusLabel: string) => (
  <Box minWidth={'5rem'}>
    <StatusChip label={statusLabel} size="small" color={getStatusColor(statusLabel)} showIcon={false} />
  </Box>
);

/**
 * Render the document link cell to allow user download of document
 * @param documentPath - the path to the document on the server
 * @param downloadFile - a function that will download the file
 * @returns the rendered document link
 */
export const renderDocumentLinkCell = (documentPath: string, downloadFile: (documentPath: string) => void) => {
  return (
    <Box>
      <Link
        component="button"
        onClick={() => {
          downloadFile(documentPath);
        }}
        data-key={documentPath}
      >
        {'WA Department of Health Letter'}
      </Link>
    </Box>
  );
};

// return upload date column formatting config
export const renderUploadDateColumn = (uploadDate: string) => {
  return DateTime.fromISO(uploadDate).toFormat('dd MMM yyyy');
};

// return created by full name
export const renderCreatedBy = (createdBy: any) => {
  return `${createdBy?.first_name || ''} ${createdBy?.last_name || ''}`;
};

/*
 * format table columns as per
 * https://tanstack.com/table/latest/docs/guide/column-defs
 * @param headings - the headings passed from API
 * @returns - table config compatible with ColumnDef doc
 */
export const formatTableColumns = (downloadDocument: (documentPath: string) => void) => {
  return [
    {
      accessorKey: 'document_status',
      header: 'Status',
      width: 100,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, string>) => renderStatusChipCell(item.getValue())
    },
    {
      accessorKey: 'upload_date',
      header: 'Uploaded date',
      width: 100,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, string>) => renderUploadDateColumn(item.getValue())
    },
    {
      accessorKey: 'document_path',
      header: 'Document',
      type: 'number',
      width: 110,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, string>) =>
        renderDocumentLinkCell(item.getValue(), downloadDocument)
    },
    {
      accessorKey: 'CreatedBy',
      header: 'Uploaded by',
      width: 160,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, any>) => renderCreatedBy(item.getValue())
    }
  ];
};

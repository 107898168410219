import { Logger } from '@/utils/logger';
import type { PreviewNotificationFormRequest } from '@montugroup/pms-api-contracts';
import { PreviewNotificationFormRequestSchema } from '@montugroup/pms-api-contracts';
import type { FetchPdfParams } from './useNoticeOfTreatmentPdf';

// TODO: [B2C-5486] Update default error message
export const DEFAULT_ERROR_MESSAGE = 'An error occurred while fetching the PDF';

export const transformDataForRequest = (data: FetchPdfParams): PreviewNotificationFormRequest => {
  const transformedData: PreviewNotificationFormRequest = {
    patientId: Number(data.patientId),
    products: data.medicationProductsPayload.map((product) => ({
      productId: Number(product.product_id),
      dosage: product.dosage as string,
      interval: Number(product.interval),
      quantity: Number(product.quantity),
      repeats: Number(product.repeats)
    }))
  };

  try {
    return PreviewNotificationFormRequestSchema.parse(transformedData);
  } catch (error) {
    const logger = new Logger('transformDataForRequest');

    logger.error('transformed data for request is not valid', error);

    return transformedData;
  }
};

export const validateResponse = (body: Blob | unknown): Blob => {
  if (!(body instanceof Blob)) {
    const logger = new Logger('validateResponse');

    logger.error('pdf blob from response is not valid');

    throw new Error();
  }

  return body;
};

export const parseErrorMessage = (error: Error | unknown): string => {
  if (error instanceof Error && Boolean(error.message)) {
    return error.message;
  }

  return DEFAULT_ERROR_MESSAGE;
};

import type { CssVarsThemeOptions } from '@mui/material/styles';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import cssVarJson from './export.json';

import MierB from './fonts/MierB/MierB-Regular.woff2';

import { fixThemeOptions } from '../../utils/theme';

const CSS_VAR_PREFIX = 'alternaleaf'; // Needs to be unique to support theme nesting

const baseFontFamily = 'Mier B, Helvetica, Arial, sans-serif';

const customizations: CssVarsThemeOptions = {
  cssVarPrefix: CSS_VAR_PREFIX,
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: baseFontFamily
  },
  components: {
    // MuiAlert overrides to fix the broken logic in extend theme.
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-error-light)`
        },
        standardWarning: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-warning-light)`
        },
        standardInfo: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-info-light)`
        },
        standardSuccess: {
          backgroundColor: `var(--${CSS_VAR_PREFIX}-palette-success-light)`
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Mier B';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Mier B'), local('Mier B-Regular'), url(${MierB}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    },
    // Temporary fixes to the export. Once the figma is fixed and exported properly, these should be removed
    MuiButton: {
      styleOverrides: {
        sizeLarge: ({ ownerState }) => ({
          padding: ownerState.variant === 'text' ? '8px 11px' : '8px 22px' // Export bug causing button hover padding change
        }),
        sizeMedium: ({ ownerState }) => ({
          padding: ownerState.variant === 'text' ? '6px 8px' : '6px 16px'
        }),
        sizeSmall: ({ ownerState }) => ({
          padding: ownerState.variant === 'text' ? '4px 5px' : '4px 10px'
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 600
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: cssVarJson.shape.paperRadius ?? 8 // todo: Confirm where UX got "paperRadius" from
        }
      }
    }
  }
};

export const alternaleafJSON = fixThemeOptions(deepmerge(cssVarJson, customizations) as CssVarsThemeOptions);

export const alternaleaf = extendTheme(alternaleafJSON);

export default alternaleaf;

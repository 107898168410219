import { Alert, AlertTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { BoxViewer, ErrorViewer, IframeViewer, StyledBox } from './NoticeOfTreatmentPDFViewer.styles';

export type NoticeOfTreatmentPDFViewerProps = {
  isPdfLoading: boolean;
  pdfError: Error | null;
  pdfString: string | null;
};

export const NoticeOfTreatmentPDFViewer: React.FC<NoticeOfTreatmentPDFViewerProps> = ({
  isPdfLoading,
  pdfError,
  pdfString
}) => {
  if (isPdfLoading) {
    return (
      <BoxViewer>
        <CircularProgress />
      </BoxViewer>
    );
  }

  if (pdfError) {
    return (
      <ErrorViewer>
        <Alert severity="error">
          <AlertTitle>Failed to load preview</AlertTitle>
          <StyledBox>{pdfError.message}</StyledBox>
        </Alert>
      </ErrorViewer>
    );
  }

  if (pdfString) {
    return <IframeViewer src={`${pdfString}#toolbar=0`} title="Notification of Treatment Form" />;
  }

  return null;
};
